import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axiosInstance, { API_ENDPOINTS } from 'src/utils/axios';

// redux
import { useDispatch } from 'src/redux/store';
import { setEnterprise } from 'src/redux/slices/select-enterprises';

//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
    user: null,
    userawscontext: null,
    userorgcontext: null,
    userentcontext: null,
    loading: true,
    usererror: null,
    permissions: {
        DeviceManagement: {
            DeviceManagement: true,
            Inventory: true,
            DeviceAlerts: true,
            OSUpdates: true,
            ScheduleDeviceScan: true,
            Enrollment: true,
            Groups: true,
            DeviceUsers: true,
            InventoryChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
            DeviceAlertsChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
            OSUpdatesChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
            ScheduleDeviceScanChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
            GroupsChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
            EnrollmentChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
            DeviceUsersChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
        },
        DeviceSecurity: {
            DeviceSecurity: false,
            Profiles: false,
            ProfilesChild: {
                delete: false,
                edit: false,
                read: false,
                write: false,
            },
        },
        ApplicationManagement: {
            ApplicationManagement: true,
            AppCatelog: true,
            AppCatelogChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
            AppStores: true,
            AppStoresChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
        },
        LocationManagement: {
            LocationManagement: true,
            GeoFencing: true,
            DeviceTracking: true,
            DeviceTrackingChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
            GeoFencingChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
        },
        SiteAdministration: {
            SiteAdministration: true,
            Permissions: true,
            Roles: true,
            Users: true,
            PermissionsChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
            RolesChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
            UsersChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
        },
        UEMSettings: {
            UEMSettings: true,
            Settings: true,
            Organisation: true,
            EnterprisesDivisions: true,
            AdministrativeBoundary: true,
            SettingsChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
            EnterprisesDivisionsChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
            OrganisationChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
            AdministrativeBoundaryChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
        },
        Tools: {
            Tools: true,
            ToolsChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
        },
        Reports: {
            Reports: true,
            ReportChild: {
                edit: true,
                read: true,
                write: true,
                delete: true,
            },
        },
    },
};

const reducer = (state, action) => {
    if (action.type === 'INITIAL') {
        return {
            loading: false,
            userawscontext: action.payload.AuthData?.Admin,
            userorgcontext: action.payload.AuthData?.Organisation,
            userentcontext: action.payload.AuthData?.Enterprise,
        };
    }
    if (action.type === 'LOGIN') {
        return {
            ...state,
            user: action.payload.user,
        };
    }
    if (action.type === 'LOGINAWS') {
        return {
            ...state,
            userawscontext: action.payload.AuthData?.Admin,
            userorgcontext: action.payload.AuthData?.Organisation,
            userentcontext: action.payload.AuthData?.Enterprise,
            // usererror: action.payload.UserError,
        };
    }
    if (action.type === 'LOGINAWS1') {
        return {
            ...state,
            userawscontext: action.payload.AuthData?.Admin,
            userorgcontext: action.payload.AuthData?.Organisation,
            userentcontext: action.payload.AuthData?.Enterprise,
            // usererror: action.payload.UserError,
        };
    }
    if (action.type === 'REGISTER') {
        return {
            ...state,
            user: action.payload.user,
        };
    }
    if (action.type === 'LOGOUT') {
        return {
            ...state,
            user: null,
            userawscontext: null,
            userorgcontext: null,
            userentcontext: null,
        };
    }
    if (action.type === 'PERMISSIONS') {
        return {
            ...state,
            permissions: action.payload.permissions,
        };
    }
    return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const reduxdispatch = useDispatch();
    console.log('outside authprovider dispatch');
    const initialize = useCallback(async () => {
        try {
            const accessToken = localStorage.getItem(STORAGE_KEY);

            if (accessToken && isValidToken(accessToken)) {
                console.log('inside');
                setSession(accessToken);

                const response = await axiosInstance.post('/user/decode_access', {
                    access_token: accessToken,
                });

                const AuthData = response.data?.Data;

                console.log(AuthData.Permissions);

                dispatch({
                    type: 'INITIAL',
                    payload: {
                        AuthData,
                    },
                });

                dispatch({
                    type: 'PERMISSIONS',
                    payload: {
                        permissions: AuthData.Permissions,
                    },
                });
            } else {
                dispatch({
                    type: 'INITIAL',
                    payload: {
                        user: null,
                    },
                });
            }
        } catch (error) {
            console.error(error);
            dispatch({
                type: 'INITIAL',
                payload: {
                    user: null,
                },
            });
        }
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize]);

    // LOGIN

    // LOGIN
    const login = useCallback(async (email, password) => {
        const data = {
            email,
            password,
        };

        const response = await axiosInstance.post(API_ENDPOINTS.auth.login, data);

        const { accessToken, user } = response.data;

        setSession(accessToken);

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        });
    }, []);

    // LOGIN AWS WITH MFA
    const loginaws = useCallback(
        async (response) => {
            console.log('inside context login', response);
            const responsedata = response.data;
            const AuthData = response.data?.Data;
            console.log('response inside context', responsedata);
            const accessTokenaws = responsedata?.AuthenticationResult.AccessToken || '';
            console.log('accessTokeaws inside context', accessTokenaws);
            setSession(accessTokenaws);
            dispatch({
                type: 'PERMISSIONS',
                payload: {
                    permissions: AuthData.Permissions,
                },
            });
            dispatch({
                type: 'LOGINAWS',
                payload: {
                    AuthData,
                },
            });
            reduxdispatch(setEnterprise(AuthData.Enterprise));
        },

        [reduxdispatch]
    );

    const loginaws1 = useCallback(
        async (response) => {
            console.log('inside context response', response);
            const responsedata = response.data;
            const AuthData = response.data?.Data;
            console.log('response1 inside context', responsedata);
            console.log('AuthData1 inside context', AuthData);
            const accessTokenaws = responsedata?.AuthenticationResult.AccessToken || '';
            console.log('accessTokeaws1 inside context', AuthData.Permissions);
            setSession(accessTokenaws);
            dispatch({
                type: 'PERMISSIONS',
                payload: {
                    permissions: AuthData.Permissions,
                },
            });
            dispatch({
                type: 'LOGINAWS1',
                payload: {
                    AuthData,
                },
            });
            // here
            console.log('inside authprovider dispatch');
            reduxdispatch(setEnterprise(AuthData.Enterprise));
        },

        [reduxdispatch]
    );

    // REGISTER
    const register = useCallback(async (email, password, firstName, lastName) => {
        const data = {
            email,
            password,
            firstName,
            lastName,
        };

        const response = await axiosInstance.post(API_ENDPOINTS.auth.register, data);

        const { accessToken, user } = response.data;

        localStorage.setItem(STORAGE_KEY, accessToken);

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        });
    }, []);

    // LOGOUT
    const logout = useCallback(async () => {
        setSession(null);
        dispatch({
            type: 'LOGOUT',
        });
    }, []);

    // ----------------------------------------------------------------------

    const checkAuthenticated = state.userawscontext ? 'authenticated' : 'unauthenticated';

    const status = state.loading ? 'loading' : checkAuthenticated;

    const memoizedValue = useMemo(
        () => ({
            user: state.user,
            userawscontext: state.userawscontext,
            userorgcontext: state.userorgcontext,
            userentcontext: state.userentcontext,
            // usererror: state.usererror,
            method: 'jwt',
            loading: status === 'loading',
            authenticated: status === 'authenticated',
            unauthenticated: status === 'unauthenticated',
            //
            permissions: state.permissions,
            //
            login,
            register,
            logout,
            loginaws,
            loginaws1,
        }),
        [
            login,
            logout,
            register,
            state.user,
            state.userawscontext,
            state.userorgcontext,
            state.userentcontext,
            loginaws,
            state.permissions,
            loginaws1,
            status,
            // state.usererror,
        ]
    );

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
    children: PropTypes.node,
};
